<template>
  <section class="center-content">
    <summary-instagram 
      :data_user="data_user" 
      :network="network"
      :is_paid="false"
      :has_expired="true"
      :subscribers_growth_prc_graph="subscribers_growth_prc_graph"
      :mentions_avatares="mentions_avatares"
      :mentions_user="mentions_user"
      @get_profile_url="unlockReport"
      v-if="ready_summary && (network === 'instagram' || network === 'youtube')"
    />

    <summary-tiktok
      :data_user="data_user"
      :network="network"
      :is_paid="false"
      :has_expired="true"
      :subscribers_growth_prc_graph="subscribers_growth_prc_graph"
      :mentions_avatares="mentions_avatares"
      :mentions_user="mentions_user"
      @get_profile_url="unlockReport"
      v-if="ready_summary && network === 'tiktok'"
    />

    <summary-twitter
      :data_user="data_user"
      :is_paid="false"
      :network="network"
      @get_profile_url="unlockReport"
      v-if="ready_summary && network === 'twitter'"
    />

    <summary-twitch
      :is_paid="false"
      :data_user="data_user" 
      :network="network"
      @get_profile_url="unlockReport"
      v-if="ready_summary && network === 'twitch'"
    />
  </section>
  
</template>

<script>
import { getCategories, getHistorySuscribers, getHistorySuscribersLaravel, unlockReport } from '@/services/search'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  components: {
    SummaryInstagram: () => import('@/views/components/sidebar/ContentProfileAnalytics.vue'),
    SummaryTiktok: () => import('@/views/components/sidebar/tiktok/ContentProfileAnalyticsTiktok.vue'),
    SummaryTwitter: () => import('@/views/components/sidebar/twitter/ContentProfileAnalyticsTwitter.vue'),
    SummaryTwitch: () => import('@/views/components/sidebar/twitch/ContentProfileAnalyticsTwitch.vue'),
  },
  props: {
    network: {
      type: String,
      default: 'instagram'
    },
    username: {
      type: String,
      default: ''
    },
    report: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },  
  data() {
    return {
      data_user: {},
      subscribers_growth_prc_graph: null,
      mentions_avatares: [],
      mentions_user: [],
      ready_summary: false
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
    if (this.network !== 'twitch') {
      this.getCategories()
    } else {
      this.formatData([])
    }
  },
  methods: {
    getCategories() {
      getCategories(this.network).then(response => {
        this.formatData(response)
      })
    },
    formatData(categories_response) {
      this.data_user = {
        ...this.report,
        parent_data: {
          username: {
            name: {
              username: this.username,
              ava: this.report.basic.avatar_url,
              avatar: this.report.basic.avatar_url,
              fullname: this.report.basic.title
            }
          },
          followers: this.report.metrics.subscribers_count.value,
          categories: {
            all_categories: this.getLabelscategories(categories_response)
          },
        }
      }

      if (this.network === 'instagram') {
        this.mentions_user = !Array.isArray(this.report.features.blogger_mentions) ? this.data_user.features.blogger_mentions.data.mentions.performance["180d"] : null;
        if (this.mentions_user) {
          for (const user in this.mentions_user.users) {
            this.mentions_avatares.push(this.mentions_user.users[user].avatar_url)
          }
          this.mentions_avatares = this.mentions_avatares.slice(0, this.data_user.features.blogger_mentions.data.reports.length)
        }
      }

      if (this.network === 'tiktok') {
        const blogger_mentions = this.data_user?.features?.blogger_mentions?.data || null;
        if (
          blogger_mentions &&
          blogger_mentions.mentions &&
          blogger_mentions.mentions.performance &&
          blogger_mentions.mentions.performance["180d"] &&
          Array.isArray(blogger_mentions.mentions.performance["180d"].data) &&
          blogger_mentions.mentions.performance["180d"].data.length > 0
        ) {
          this.mentions_user = blogger_mentions.mentions.performance["180d"];
        } else {
          this.mentions_user = null;
        }
      }

      if (this.network !== 'twitch') {
        this.fetchSubscribersHistory(this.username);
      }

      this.ready_summary = true;
    },
    getLabelscategories(categories_response) {
      const categories = this.network !== 'youtube' ? categories_response?.labels || [] : categories_response?.thematics || []
      const ids = this.network !== 'youtube' ? this.report?.features?.blogger_labels?.data?.slice(0, 6) || [] : this.report?.features?.blogger_thematics?.data?.slice(0, 5) || [];

      const categories_labels = [];

      ids.forEach(category => {
        const exists = categories.find((cat => cat.id === category));
        if (exists) {
          categories_labels.push(exists.title);
        }
      });
      return categories_labels
    },
    fetchSubscribersHistory(username) {
      const fetchFunction = this.network === "instagram" ? getHistorySuscribers : getHistorySuscribersLaravel;
      const metric_key = this.network === "instagram" ? "followers_count" : "subscribers_count";

      fetchFunction({ network: this.network, username })
        .then((response) => {
          if (response && Array.isArray(response.metrics) && response.metrics[metric_key]) {
            this.subscribers_growth_prc_graph = response.metrics[metric_key].history.map(item => ({
              count: item.value,
              date: Math.floor(new Date(item.time_iso).getTime() / 1000)
            }));
          }
        });
    },
    unlockReport() {
      const data = {
        username: this.username,
        network: this.network
      }

      unlockReport(data).then((response) => {
        if (response.processing) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: 'Obteniendo métricas',
                text: 'Estamos trabajando para obtener toda la información posible del perfil, puedes seguir navegando y te avisaremos cuando este lista',
                icon: 'CoffeeIcon',
                variant: 'success',
              }
            })
          })
        } else {
          this.$toast.clear();
          this.$router.push({name: 'auth-perfil', params: {'alias': this.username, 'network': this.network}})
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 75%;
}
</style>